<template>


  <div class="container has-background-white-ter">
    <section id="users-timetable" class="section">
      <div class="container">
        <div class="box"
          style="align-content: flex-start; flex-direction: row; justify-content: space-between; display: flex;">
          <h4 class="title is-4 is-spaced">Cover Timetable</h4>

          <div>
            <router-link  v-if="previous"  :to="{ path: '/school/timetable/cover/'+previous+'/' }">{{ previousF }}</router-link>
            <span v-else>{{ previousF }}</span>
            &lt; <strong>{{ dateF }}</strong> &gt;
            <router-link   :to="{ path: '/school/timetable/cover/'+nextd+'/' }">{{ nextdF }}</router-link>


          </div>
        </div>
      </div>
    </section>


    <section id="users-timetable" class="section">
        <div class="tabs is-centered" v-if="numDays>1">
          <ul>
            <li v-for="(Staff,lDay) in days" :key="lDay" :class="(day == lDay) ? 'is-active' : ''" ><a @click="day = lDay" >{{ lDay }}</a></li>
          </ul>
        </div>









      <div class="container" v-if="loaded">

        <table class="table" style="width:100%">
          <thead>
          <tr>
            <th>Name</th>
            <th v-for="Period in periods[day]" :key="Period.id" style="text-align: center;"   :class="(period == Period.id) ? 'has-background-primary-light ' : ''" ><a @click="period = Period.id" >{{ Period.periodName }}</a><br />{{ Period.startTime }} - {{ Period.endTime }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="sm in staff()" :key="sm.ident">
            <th>{{ sm[1].displayName}}</th>
            <td v-for="Period in periods[day]" :key="Period.id" :class="(period == Period.id) ? 'has-background-primary-light' : ''" >
              <strong>{{ sm[1].details[day][Period.id]?.description }}</strong><br />
              {{ sm[1].details[day][Period.id]?.groupName }} <small v-if="sm[1].details[day][Period.id]?.roomName">({{ sm[1].details[day][Period.id].roomName }})</small>
            </td>
          </tr>

        </tbody>
        </table>




      </div>
      <ModalProgress v-else message="Retrieving from your MIS - can take several seconds" title="Loading...." />

    </section>


  </div>
</template>

<script>

//import RTT from "Components/school/rtt.vue";
import ModalProgress from "Components/ModalProgress.vue";
import { RouterLink } from "vue-router";
import { format } from "date-fns";



export default {
  name: "home",

  components: {
    ModalProgress,
    RouterLink
},

  props: ["date",],
  data: function () {
    return {
      timetables: {},
      hasTimetables: false,
      loaded: false,
      days: {},
      day: null,
      numDays: 0,
      periods: {},
      period: 0,

    };
  },

  computed: {

    dateF() {
      return format(new Date(this.date), "do MMM yyyy")
    },

    previous() {
      if (this.date <= this.$store.state.currentISODate) {
        return false
      }

      const yesterday = new Date(this.date);
      yesterday.setDate(yesterday.getDate() - 1)
      return format(yesterday, "yyyy-MM-dd")

    },
    previousF() {
      const yesterday = new Date(this.date);
      yesterday.setDate(yesterday.getDate() - 1)
      return format(yesterday, "do MMM yyyy")
    },

    nextd() {

      const yesterday = new Date(this.date);
      yesterday.setDate(yesterday.getDate() + 1)
      return format(yesterday, "yyyy-MM-dd")

    },
    nextdF() {
      const yesterday = new Date(this.date);
      yesterday.setDate(yesterday.getDate() + 1)
      return format(yesterday, "do MMM yyyy")
    }
    //  nextd: "",
    //  nextdF: "",



  },

  methods: {

    staff() {

      console.log("In staff")
      const thatDay = this.day
      const thatPeriod = this.period

      if (thatPeriod === "x") {
        return []
      }
      let rtn = Object.entries(this.timetables ?? {}).filter(x => {
        console.log(x)
        if (!x[1].details?.[thatDay]) {
          return false
        }

        console.log(x[1].details[thatDay])
        let rtn2 =  Object.values(x[1].details[thatDay]).filter(x => {

          if (x.periodIdent != thatPeriod) {
            return false
          }

          if (x.groupIdent) {
            return false
          }

          if (x.description == "Reserved") {
            return false
          }

          return x.description != "Unavailable"
        })

        return rtn2.length > 0
      })




      return rtn
    },

    loadTimeTable() {
    //    console.log([process.env.VUE_APP_API_BASE, 123])

    this.timetables = {}

    this.loaded = false;
    this.currentTeacher = this.$store.getters.currentUserDetails?.StaffId
    this.hasTimetables = false

    this.$api.get("v2/staff/timetable/rtt/all/"+this.date, true, true).then((response) => {

      let rtn = {};

      if (!response) {
        this.timetables = rtn
        this.loaded = true
        return
      }

      let days = {}
      this.periods = {}

      Object.values(response).forEach(teacher => {

        if (!teacher.details || teacher.details.length == 0) {
          return;
        }

        if (teacher.details.filter(x => x.groupIdent).length == 0) {
          return;
        }



        teacher.details.sort(function (a, b) {

          if (a.startTime < b.startTime) {
            return -1
          }
          if (a.startTime > b.startTime) {
            return 1
          }
          return 0
        })



        teacher.details = teacher.details.reduce((p, c) => {

          if (!c.periodIdent) {
            return p
          }


          if (!this.periods[c.timetableDayName]) {
            this.periods[c.timetableDayName] = {}
          }

          if (!this.periods[c.timetableDayName][c.periodIdent]) {
            this.periods[c.timetableDayName][c.periodIdent] = {
              periodName: c.periodName,
              startTime: c.startTime,
              endTime: c.endTime
            }
          }

          if (!p[c.timetableDayName]) {
            let y = {}
            y[c.timetableDayName]=[c]
            return y
          }

          if (c.periodIdent != p[c.timetableDayName].at(-1).periodIdent) {
            p[c.timetableDayName].push(c)
            return p
          }

          let d = p[c.timetableDayName].pop()

          if (!d.others) {
            d.others = [c]
          }
          else if (d.others.length && (d.others.at(-1).groupIdent != c.groupIdent)) {
            d.others.push(c)
          }

          p[c.timetableDayName].push(d)


          return p

        }, {})


        Object.keys(teacher.details).forEach(x => {
          days[x]=[...(days[x] ?? []), { id: teacher.ident,name: teacher.displayName} ]

          teacher.details[x] = teacher.details[x].reduce((p, c) => {
            p[c.periodIdent] = c
            return p
          }, {})

        })

        rtn[teacher.ident] = teacher
        this.hasTimetables = true

      })

      this.timetables = rtn

      console.log(rtn)
      this.loaded = true
      this.days = days

      console.log({DAYS: this.days})


      let x = Object.keys(days)

      this.numDays = x.length

      if (x.length > 0) {
        this.day = x[0]

        let d = Object.keys(this.periods)

        for (let i of d) {
          console.log(i)

          this.periods[i] = Object.entries(this.periods[i]).map(y=> {
            y[1].id = y[0]
            return y[1]
          }).sort((a,b) => {
            if (a.startTime < b.startTime) return -1
            if (a.startTime > b.startTime) return 1
            return 0
          })
        }

        this.period = this.periods[x[0]][0].id
      }

    });
  },
  },


  mounted() {
    this.loadTimeTable()
  },

  watch: {
    date(_newValue, _oldValue) {
      this.loadTimeTable()
    }
  }



};
</script>
